export default {
  Message: {
    Confirm: 'Ok',
    Cancel: 'Close',
    Title: 'Message',
    msg401: 'Insufficient authority!',
    msg400: 'System abnormal!',
    msg404: 'Request interface does not exist!',
    msg500: 'Server error!',
    msg502: 'Network error!',
    msg503: 'The service is not available, the server is temporarily overloaded or maintained.'
  },
  DeliveryAddress: {
    Address: 'Address'
  },
  Order: {
    OrderNumber: 'Order Number',
    OrderDateTime: 'Order Date & Time',
    TotalPrice: 'TotalPrice',
    OrderStatus: 'Order Status',
    Paid: 'Paid',
    Unpaid: 'Unpaid',
    PayNow: 'PayNow',
    Tips: '*All paid fees (including deposit) will not be refunded.'
  },
  RegNPay: {
    BackText: 'Back',
    UploadButtonText: 'Upload files(jpg, png, pdf)',
    UploadingText: 'Uploading...',
    ConfirmText: 'OK',
    UploadSuccessfulText: 'Upload Successful',
    UploadFailText: 'Upload failed',
    NoFileText: 'No file selected',
    UploadLengthText: 'You can only upload {0} files at most',
    UploadSizeText: 'You can only upload {0} M at most',
    PleaseSelect: 'Please Select',
    SubRecord: 'Submission Record',
    ReferenceNum: 'Reference Number',
    SubmissionDate: 'Submission Date',
    Print: 'Print',
    PlsPayWay: 'Please select the mode of payment',
    PreviewTitleText: 'Please confirm your information again.',
    FormatErrorText: 'Please input correct email address.',
    RequiredText: 'Required'
  },
  Display: {
    SuccessCase: 'Successful Case',
    GeneralQuery: 'General Query',
    OfficeHours: 'Office Hours',
    Hour0: 'Monday to Friday：',
    Hour1: '9:00 a.m. to 12:00 p.m.,',
    Hour2: 'From 1:30 p.m. to 6 p.m',
    Hour3: 'Closed on Saturdays, Sundays and public holidays',
    AddrInfo: "Rm 306, 3 / f, tin's enterprise centre, 777 lai chi kok road, cheung sha wan (cheung sha wan MTR station exit B)",
    More: 'Read More',
    Join: 'join',
    Package: 'Package',
    PlanA: 'Starter',
    PlanB: 'Premium',
    PlanC: 'Elite',
    Plan: 'Plan',
    Title: 'Other Information',
    Register: 'FAQ',
    FAQ: 'Contact Us',
    Advantages: 'Advantages',
    Details: 'Company Secretary Details',
    Tips: '#Plan B (Only choose 2 items from A to F, including the change once a year)',
    Plan1: 'Solo (within 5 to 7 working days)',
    Plan2: 'Business (within 5 to 7 working days)',
    Plan3: 'Entrepreneur (1 working day only)'
  },
  Action: {
    ViewMore: 'View More',
    SignUp: 'Sign Up',
    ReturnHomePage: 'Return Home Page',
    OrderDetails: 'Order Details'
  },
  Cms: {
    PlsChoose: 'Please click to select the content you want to view :',
    Intro: 'We sincerely respond to customer needs and provide high-quality one-stop solutions for the establishment of Hong Kong companies, company secretarial service, and professional consultants. Upstream is your most reliable company secretary partner.',
    More: 'More info',
    Contact: 'Find Us',
    Con1: 'Want to learn more about our services?',
    Con2: 'Welcome to contact us to answer any questions for you',
    Loca: 'Room 1305, 13/F, Hermes Commercial Centre, 4-4A Hillwood Road, Tsim Sha Tsui, Kowloon, Hong Kong',
    Con3: 'Thank you for supporting Upstream! ',
    Con4: 'We have received your reply and will get back to you shortly. ',
    Con5: 'Thank you for supporting Upstream!',
    Con6: 'We have received your reply and will get back to you shortly. '
  },
  payment: {
    PaymentSuccessTips: 'Payment Result : Success',
    PaymentFailTips: 'Payment Result : Fail'
  },
  Calendar: {
    KnowMore: 'Know More',
    Time: 'Time',
    Venue: 'Venue',
    NoEvent: 'No activity today'
  },
  photo: {
    photo: 'photo',
    BackText: 'Back to previous'
  }
};
