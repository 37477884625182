







import { Component, Vue, Watch } from 'vue-property-decorator';
import { FrontE } from '@/sdk/common/SysConst';

@Component
export default class App extends Vue {
  private queryLang: string = '';
  // 建站中跳转
  beforeCreate () {
    if (((FrontE.pcBuilding && !this.$store.state.isMobile) || (FrontE.mobileBuilding && this.$store.state.isMobile)) && this.$route.name !== 'building') {
      this.$store.dispatch('setOrgPath', this.$route.path);
      this.$router.push('/building');
    }
  }

  beforeUpdate () {
    if (this.$route.query.returnUrl) {
      let url = this.$route.query.returnUrl + '?';
      Object.keys(this.$route.query).forEach((e) => {
        if (e !== 'returnUrl') url += e + '=' + this.$route.query[e] + '&';
      });
      this.$router.push(url);
    } else {
      this.$nextTick(() => {
        this.$Api.promotion.GetHomeSeoData().then(result => {
          document.title = result.Data.Title;
        });
      });
    }
  }
  // 获取url参数
  getQueryString (name) {
    // eslint-disable-next-line no-useless-escape
    var result = window.location.search.match(new RegExp('[\?\&]' + name + '=([^\&]+)', 'i'));
    if (result == null || result.length < 1) {
      return '';
    }
    return result[1];
  }

  get isMobile () {
    return this.$store.state.isMobile;
  }
  created () {
    this.queryLang = this.getQueryString('Lang');
    // 根據url傳參優先設置语言
    if (this.queryLang) {
      this.$Api.member.setUILanguage(this.queryLang).then((result) => {
        this.$i18n.locale = this.queryLang as string;
        localStorage.setItem('locale', this.queryLang as string);
      }).catch((error) => {
        console.log(error);
      });
    }
  }

  mounted () {
    Vue.prototype.$ShowLayer();
    setTimeout(() => { Vue.prototype.$HiddenLayer(); }, 2000);
  }

  @Watch('isMobile', { deep: true })
  onMediaChange () {
    if (((FrontE.pcBuilding && !this.isMobile) || (FrontE.mobileBuilding && this.isMobile)) && this.$route.name !== 'building') {
      this.$store.dispatch('setOrgPath', this.$route.path);
      this.$router.push('/building');
    } else if (this.$route.name === 'building') {
      this.$router.push(this.$store.state.orgPath);
    }
  }
}
