export default {
  Message: {
    Confirm: '確認',
    Cancel: '關閉',
    Title: '信息',
    msg401: '權限不足！',
    msg400: '系統異常！',
    msg404: '請求接口不存在！',
    msg500: '服務器錯誤！',
    msg502: '网络错误！',
    msg503: '服務不可用，服務器暫時過載或維護。'
  },
  DeliveryAddress: {
    Address: '地址'
  },
  Order: {
    OrderNumber: '訂單編號',
    OrderDateTime: '訂單日期&時間 ',
    TotalPrice: '總價',
    OrderStatus: '訂單狀態',
    Paid: '已付款',
    Unpaid: '待付款',
    PayNow: '立即支付',
    Tips: '*確認付款完成後，按金將不予退還*'
  },
  RegNPay: {
    BackText: '返回',
    UploadButtonText: '上傳文件(jpg, png, pdf)',
    UploadingText: '正在上傳',
    ConfirmText: '確定',
    UploadSuccessfulText: '上傳成功',
    UploadFailText: '上傳失敗',
    NoFileText: '沒有選中文件',
    UploadLengthText: '最多只能上載{0}檔案',
    UploadSizeText: '最多只能上傳{0}M',
    PleaseSelect: '請選擇',
    SubRecord: '提交紀錄',
    ReferenceNum: '參考編號',
    SubmissionDate: '提交日期',
    Print: '列印',
    PlsPayWay: '請選擇付款方式',
    PreviewTitleText: '請再次確認頁面',
    FormatErrorText: '請輸入正確電郵地址',
    RequiredText: '請檢查你的答案'
  },
  Display: {
    SuccessCase: '成功個案',
    GeneralQuery: '壹般查詢',
    OfficeHours: '辦公時間',
    Hour0: '星期壹至五：',
    Hour1: '早上9時至下午12時,',
    Hour2: '下午1時30分至晚上6時',
    Hour3: '星期六、日及公眾期假休息',
    AddrInfo: '長沙灣荔枝角道 777號田氏企業中心 3樓 306室 (長沙灣港鐵站 B出口)',
    More: '更多資料',
    Join: '選擇',
    Package: '方案',
    PlanA: '優越',
    PlanB: '精貴',
    PlanC: '尊尚',
    Plan: '方案',
    Title: '其他資訊',
    Register: '一般問題',
    FAQ: '聯絡我們',
    Advantages: '優勢及目的',
    Details: '公司秘書服務詳情',
    Tips: '#方案B此服務每次只可選擇A至F其中兩項，包每年更改一次。',
    Plan1: '始創(需5-7個工作天)',
    Plan2: '商業(需5-7個工作天)',
    Plan3: '企業(只需1個工作天)'
  },
  Action: {
    ViewMore: '查看更多',
    SignUp: '立即報名',
    ReturnHomePage: '返回首頁',
    OrderDetails: '訂單詳情'
  },
  Cms: {
    PlsChoose: '請點擊選擇您想要查看的內容：',
    Intro: '我們真誠回應客戶的需求，提供高質量的一站式成立香港公司、公司秘書、專業顧問等方案。共融從此變得簡單，是你最可靠的公司秘書夥伴。',
    More: '更多資料',
    Contact: '聯繫我們',
    Con1: '想了解更多我們的服務？',
    Con2: '歡迎聯絡我們，為你解答任何疑難',
    Loca: '香港九龍尖沙咀山林道4-4A號恆貿商業大廈13樓1305室',
    Con3: '感謝您對共融倡自強的支持！',
    Con4: '我們將盡快回覆你的意見或查詢，亦歡迎任何形式的合作機會，敬請耐心等候。',
    Con5: '感謝您對共融倡自強的支持！',
    Con6: '我們將盡快回覆你的意見或查詢，亦歡迎任何形式的合作機會，敬請耐心等候。'

  },
  payment: {
    PaymentSuccessTips: '支付結果： 成功',
    PaymentFailTips: '支付結果： 失敗'
  },
  Calendar: {
    KnowMore: '了解更多',
    Time: '時間',
    Venue: '地點',
    NoEvent: '今日暫無活動'
  },
  photo: {
    photo: '相片',
    BackText: '返回上一級'
  }
};
